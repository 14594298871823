<template>
  <section>
    <TopBar/>
    <Header/>
    <NavBar/>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background"  v-if="post">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>News &amp; Events</li><span class="arrow">></span>
                  <li>{{post.blogTitle}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <div v-if="error">{{error}}</div>
                    <div v-if="post" class="post">
                        <h3>{{post.blogTitle}}</h3>
                        <h5>Posted on : <span> {{post.date.toDate().toLocaleString('en-us',{dateStyle:'long'})}} </span></h5>     
                        <p class="pre" v-html="post.blogHTML"></p>
                    </div>
                    <div v-else class="posts">
                        <Loading/>
                    </div>
                </div>
              
          <div class="sidebar">
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script>

import Footer from '../../components/page/Footer.vue'
import getPost from "@/composables/getPost"
import TopBar from '../../components/page/TopBar.vue';
import Header from '../../components/page/Header.vue';
import NavBar from '../../components/page/NavBar.vue';
import RecentPosts from '../../components/page/RecentPosts.vue';
import Loading from '../../components/Loading.vue';

export default {
    name: 'ViewBlog',    
    props: ["id"],  
    components: { Footer, TopBar, Header, NavBar, RecentPosts, Loading },
    setup(props){
        const { post, error } = getPost(props.id);

        return {
            post, error
      }
  }
}
</script>

<style scoped>
.posts{
    display: flex;
    justify-content: center;
}
.page :deep(li){
  margin-left: 2rem;
}

.page :deep(h3){
    margin-top: 2rem;
}
.page :deep(h5){
    padding-top: 0.5rem;
}
.page :deep(img){
    width: 100%;
}
.page :deep(h5 span){
    font-weight: 400;
}
@media (min-width:768px) {
    .page :deep(h3){
    margin-top: 1rem;
}
}
</style>